.quotes {
  blockquote {
    border-left: none;
    margin: 1em;
    text-align: center;
  }

  .hint-text {
    font-style: italic;
    text-align: center;
  }

  #quotes-list li {
    list-style-type: none;
    padding: 10em 0;
  }

  .jumbotron {
    background-color: #FEFEFE;
  }

  .jumbotron blockquote p {
    font-size: 1.5em;
    font-style: italic;
  }

  blockquote p a,
  blockquote p a:hover {
    color: black;
    text-decoration: none;
  }

  a.quote-link {
    color: #2196F3;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    margin: 1rem;
  }
}
